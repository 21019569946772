import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import classNames from 'clsx';
import { Link } from 'gatsby';
import { withAssetPrefix } from 'gatsby';
import React, { Component } from 'react';

type Props = {
  classes: {
    root: string;
    background: string;
    container: string;
    backgroundColorWhite: string;
    componentWrapper: string;
    messageContainer1: string;
    messageContainer2: string;
    message1: string;
    image: string;
    imageWrapper: string;
    label: string;
    backgroundColorPink: string;
    backgroundColor: string;
    message2: string;
    seeAll: string;
    seeAllLink: string;
    seeAllText: string;
    titleWrapper: string;
    title1: string;
    title2: string;
  };
};

const backgroundImage = '/assets/images/service8.webp';

class IndexWhatWeDo extends Component<Props> {
  render() {
    const { classes } = this.props;

    return (
      <section id="what-we-do" className={classes.root}>
        <div className={classes.background}>
          <div className={classes.container} />
          <div className={classes.titleWrapper}>
            <div className={classNames(classes.title1, 'barrio')}>
              CAREER
              <br />
              BUILDING
            </div>
            <div className={classNames(classes.title2, 'corporate-logo')}>
              - キャリア支援事業 -
            </div>
          </div>
        </div>
        <div
          className={classNames(classes.componentWrapper, 'sawarabi-gothic')}
        >
          <div className={classes.imageWrapper}>
            <img
              src="/assets/images/service9.webp"
              alt="ワークショップ各種"
              className={classes.image}
            />
            <div className={classes.label}>ワークショップ各種</div>
          </div>
          <div className={classes.imageWrapper}>
            <img
              src="/assets/images/service10.webp"
              alt="勉強会各種"
              className={classes.image}
            />
            <div className={classes.label}>勉強会各種</div>
          </div>
          <div className={classes.imageWrapper}>
            <img
              src="/assets/images/service4.webp"
              alt="その他、各種ソーシャル事業"
              className={classes.image}
            />
            <div className={classes.label}>
              なでケア X ABU
              <br />
              キャリア支援プログラム
            </div>
          </div>
        </div>
        <div className={classes.seeAll}>
          <Link className={classes.seeAllLink} to={'/news'}>
            <div className={classNames(classes.seeAllText, 'barrio')}>
              <Link
                to={`/news?tag=${encodeURIComponent('キャリアビルディング')}`}
              >
                キャリア支援事業関連ニュースはこちらから
              </Link>
              <img
                src="/assets/images/touch_icon.webp"
                alt="touch_icon"
                width="40"
              />
            </div>
          </Link>
        </div>
      </section>
    );
  }
}
const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 40,
      paddingBottom: 40,
      backgroundColor: 'white',
    },
    background: {
      maxWidth: 1280,
      position: 'relative',
      marginBottom: 60,
      margin: '0 auto',
    },
    container: {
      backgroundImage: `url(${withAssetPrefix(backgroundImage)})`,
      display: 'flex',
      alignItems: 'center',
      height: 300,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: '50% 40%',
      opacity: 0.7,
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        height: 240,
      },
      [theme.breakpoints.down('xs')]: {
        height: 220,
      },
    },
    componentWrapper: {
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      flexWrap: 'wrap',
    },
    imageWrapper: {
      padding: '0 20px 40px 20px',
      margin: '0 40px',
    },
    image: {
      width: 300,
      height: 200,
      objectFit: 'cover',
      overflow: 'hidden',
    },
    seeAll: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 70,
      marginTop: 80,
    },
    seeAllLink: {
      height: 40,
      textDecoration: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'inline-block',
      textAlign: 'center',
    },
    seeAllText: {
      display: 'flex',
      textDecoration: 'none',
      color: '#333',
      fontWeight: 'bold',
      fontSize: 20,
      transform: 'translateY(-50%)',
      position: 'relative',
      top: '50%',
      lineHeight: '20px',
      marginBottom: 0,
    },
    label: {
      textAlign: 'center',
      backgroundColor: '#98F661',
      width: 300,
      height: 80,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 'bold',
    },
    titleWrapper: {
      position: 'absolute',
      left: 20,
      bottom: 20,
      textAlign: 'center',
    },
    title1: {
      fontSize: 50,
      color: '#000',
      marginLeft: 40,
      lineHeight: 1.2,
      opacity: 1,
      [theme.breakpoints.down('sm')]: {
        fontSize: 30,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
      },
    },
    title2: {
      color: '#000',
      fontSize: 24,
    },
  });

export default withStyles(styles)(IndexWhatWeDo);
