import React, { Component, JSX } from 'react';

import Layout from '@/components/layout';
import SEO from '@/components/seo';
import CareerBuilding from '@/components/service/CareerBuilding';
import Hero from '@/components/service/Hero';
import OurService from '@/components/service/OurService';
import SocialActions from '@/components/service/SocialActions';

type Props = {
  requestProcess: void;
  completeProcess: void;
  isProcessing: boolean;
  data: Record<string, unknown>;
  wpModel: Record<string, unknown>;
  classes: Record<string, string>;
};

class AssistantServicePage extends Component<Props> {
  render(): JSX.Element {
    return (
      <Layout className="pt20">
        <SEO title="サービス" />
        <Hero />
        <OurService />
        <SocialActions />
        <CareerBuilding />
      </Layout>
    );
  }
}

export default AssistantServicePage;
