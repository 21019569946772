import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { withAssetPrefix } from 'gatsby';
import React, { Component } from 'react';

const backgroundImage = '/assets/images/service1.png';

type Props = {
  classes: {
    background: string;
    container: string;
  };
};

class IndexHero extends Component<Props> {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.background}>
        <div className={classes.container} />
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    background: {
      maxWidth: 1280,
      position: 'relative',
      margin: '0 auto 0 auto',
    },
    container: {
      backgroundImage: `url(${withAssetPrefix(backgroundImage)})`,
      display: 'flex',
      alignItems: 'center',
      height: 500,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      [theme.breakpoints.down('md')]: {
        height: 480,
      },
      [theme.breakpoints.down('sm')]: {
        height: 440,
      },
      [theme.breakpoints.down('xs')]: {
        height: 220,
      },
    },
  });

export default withStyles(styles)(IndexHero);
